import React from 'react';
import styled, { css } from "styled-components";

const ErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ErrorContainer = styled.div(({ theme }) => css`
  padding: 50px 20px;
  h1 {
    font-size: ${theme.fonts.sizes.h2};
    color: ${theme.palette.status.bad};
    margin-bottom: 12px;
  }

  p {
    font-size: ${theme.fonts.sizes.h6};
    margin-bottom: 8px;
  }
`);
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <ErrorContainer>
            <h1>Loading Failed</h1>
            <p>Sorry, something went wrong.</p>
            <p>Please try <strong>reloading</strong> this page.</p>
          </ErrorContainer>
        </ErrorWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
