import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const ErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ErrorContainer = styled.div(({ theme }) => css`
  max-width: 540px;
  padding: 50px 20px;
  h1 {
    font-size: ${theme.fonts.sizes.h2};
    color: ${theme.palette.status.bad};
    margin-bottom: 12px;

    @media (min-width: ${theme.breakpoints.S}) {
      font-size: ${theme.fonts.sizes.h1};
    }
  }

  p {
    font-size: ${theme.fonts.sizes.h6};
    margin-bottom: 8px;
  }
`);

const PreHeading = styled.div(({ theme }) => css`
  font-size: ${theme.fonts.sizes.p1};
`);

export const FourOhFour = () => {
  return (
    <ErrorWrapper>
      <ErrorContainer>
        <PreHeading>This page doesn't exist</PreHeading>
        <h1>404</h1>
        <p>Sorry, the page you are looking for may have been moved, deleted, or possibly never existed.</p>
        <Link to="/">Go back to the dashboard</Link>
      </ErrorContainer>
    </ErrorWrapper>
  );
};
