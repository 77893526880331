import { all, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import { determineBrandAndBrandKey, getBrandFromHost } from "@theme/config";

function* setInitialTheme() {
  const host = window.location.hostname.toLocaleLowerCase();
  const brandKeyFromHost = getBrandFromHost(host);
  const { brand, brandKey } = determineBrandAndBrandKey(brandKeyFromHost);
  yield put(actions.changeTheme({ brandKey, brand }));
}

export const __TEST__ = {
  setInitialTheme,
};

export default function* rootSaga() {
  yield all([
    takeLatest(actions.INITIALISE_QUERY_STRING, setInitialTheme)
  ]);
}
