import { getBrandFromHost } from "@theme/config";

export const convertDomainToCorrespondingBrand = () => {
  const host = window.location.hostname.toLowerCase();
  const brand = getBrandFromHost(host);

  switch (brand) {
    case "defaultBrand":
      return "Fgf";
    case "nimbleBrand":
      return "Nimble";
    default:
      return "Fgf";
  }
};
