import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authService from "./services/authService";
import TagManager from 'react-gtm-module';
import { environmentSettings } from "./constants/environmentSettings";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

const tagManagerArgs = {
  gtmId: environmentSettings.gtmId,
  auth: environmentSettings.gtmAuth,
  preview: environmentSettings.gtmPreview
}

TagManager.initialize(tagManagerArgs);

// bootstrap singleton auth service.
// this was originally on a useState(() => new authService()) inside of the AuthProvider
// however something was causing the Provider to re-attach and thus create two authServices
// of which oidc-client-js does not provide any documented ways to completly cleanup
// the auth service and I am not sure if calling the methods I found on the event
// object would be enough to fully clean up. Therefore please either keep this
// here OR find a way to stop it from remounting OR if you have found the proper way
// to fully deregister an authService
window.authService = new authService();

ReactDOM.render(
  <HelmetProvider context={helmetContext}>
    <React.StrictMode>
      <App>
        <Helmet>
        </Helmet>
      </App>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export { applyBankStepSchema } from "./containers/Forms/schema/applyBankStepSchema";