import { GOOGLE_API_KEY } from "./sharedConfig";

export const domainSpecificConfig = {
  local: {
    FGF: {
      apiManagement: 'https://fairgo-dev-api-management.azure-api.net',
      ocpApimSubscriptionKey: '3af7404c30fb44f78fb622043a277386',
      googleApiKey: GOOGLE_API_KEY,
      authority: "fairgo",
      bankStatementIframeUrl: 'https://bs.fgfdev.com.au',
      portalDebug: true,
      authUrl: 'https://auth.fgfdev.com.au',
      appUrl: 'https://portal.fgf.localhost',
      cookieDomain: 'portal.localhost',
      authClientId: 'fgf.portal',
      publicWebsite: 'https://website.fgfdev.com.au',
      newCustomerForm: 'https://ui.fgfdev.com.au',
      contentManagementUrl: 'https://cds.fgfdev.com.au',
      contentToken: '17bc8584ecaaa3a3defff4d67fd13fafbd0029ebb127feb4917b7d421761e7d5957a5432403a106ff29f8bd31f29518ad4fe9b66b1a58b55cbcc141836a3c102c06a43b1d7550f5590c487a9bffe53517aed3da07b96165f276d59b81b16095b2026d55dc2c594ea0cc5544128cd9302b7e42bc58bcc324378b2d06ead5e77b0',
      fsaPrivacyPolicy: 'https://website.fgfdev.com.au/legal-and-compliance/fintech-services-privacy-policy',
      gtmId: 'GTM-MMDH47J',
      gtmAuth: 'yrDHMzYQxivp5pjLK5hVug',
      gtmPreview: 'env-314'
    },
    NIM: {
      apiManagement: 'https://fairgo-dev-api-management.azure-api.net',
      ocpApimSubscriptionKey: '3af7404c30fb44f78fb622043a277386',
      googleApiKey: GOOGLE_API_KEY,
      authority: "nimble",
      bankStatementIframeUrl: 'https://bs.fgfdev.com.au',
      portalDebug: true,
      authUrl: 'https://auth.nimble-dev.com.au',
      appUrl: 'https://portal.nimble.localhost',
      cookieDomain: 'portal.nimble.localhost',
      authClientId: 'fgf.portal',
      publicWebsite: 'https://website.fgfdev.com.au',
      newCustomerForm: 'https://ui.nimble-dev.com.au',
      contentManagementUrl: 'https://cds.fgfdev.com.au',
      contentToken: '17bc8584ecaaa3a3defff4d67fd13fafbd0029ebb127feb4917b7d421761e7d5957a5432403a106ff29f8bd31f29518ad4fe9b66b1a58b55cbcc141836a3c102c06a43b1d7550f5590c487a9bffe53517aed3da07b96165f276d59b81b16095b2026d55dc2c594ea0cc5544128cd9302b7e42bc58bcc324378b2d06ead5e77b0',
      fsaPrivacyPolicy: 'https://website.fgfdev.com.au/legal-and-compliance/fintech-services-privacy-policy',
      gtmId: 'GTM-KR92XT',
      gtmAuth: '',
      gtmPreview: ''
    }
  },
  development: {
    FGF: {
      apiManagement: 'https://fairgo-dev-api-management.azure-api.net',
      ocpApimSubscriptionKey: '3af7404c30fb44f78fb622043a277386',
      googleApiKey: GOOGLE_API_KEY,
      authority: "fairgo",
      bankStatementIframeUrl: 'https://bs.fgfdev.com.au',
      portalDebug: true,
      authUrl: 'https://auth.fgfdev.com.au',
      appUrl: 'https://portal.fgfdev.com.au',
      cookieDomain: 'fgfdev.com.au',
      authClientId: 'fgf.portal',
      publicWebsite: 'https://website.fgfdev.com.au',
      newCustomerForm: 'https://ui.fgfdev.com.au',
      contentManagementUrl: 'https://cds.fgfdev.com.au',
      contentToken: '17bc8584ecaaa3a3defff4d67fd13fafbd0029ebb127feb4917b7d421761e7d5957a5432403a106ff29f8bd31f29518ad4fe9b66b1a58b55cbcc141836a3c102c06a43b1d7550f5590c487a9bffe53517aed3da07b96165f276d59b81b16095b2026d55dc2c594ea0cc5544128cd9302b7e42bc58bcc324378b2d06ead5e77b0',
      fsaPrivacyPolicy: 'https://website.fgfdev.com.au/legal-and-compliance/fintech-services-privacy-policy',
      gtmId: 'GTM-MMDH47J',
      gtmAuth: 'yrDHMzYQxivp5pjLK5hVug',
      gtmPreview: 'env-314'
    },
    NIM: {
      apiManagement: 'https://fairgo-dev-api-management.azure-api.net',
      ocpApimSubscriptionKey: '3af7404c30fb44f78fb622043a277386',
      googleApiKey: GOOGLE_API_KEY,
      authority: "nimble",
      bankStatementIframeUrl: 'https://bs.fgfdev.com.au',
      portalDebug: true,
      authUrl: 'https://auth.nimble-dev.com.au',
      appUrl: 'https://portal.nimble-dev.com.au',
      cookieDomain: 'nimble-dev.com.au',
      authClientId: 'fgf.portal',
      publicWebsite: 'https://website.fgfdev.com.au',
      newCustomerForm: 'https://ui.nimble-dev.com.au',
      contentManagementUrl: 'https://cds.fgfdev.com.au',
      contentToken: '17bc8584ecaaa3a3defff4d67fd13fafbd0029ebb127feb4917b7d421761e7d5957a5432403a106ff29f8bd31f29518ad4fe9b66b1a58b55cbcc141836a3c102c06a43b1d7550f5590c487a9bffe53517aed3da07b96165f276d59b81b16095b2026d55dc2c594ea0cc5544128cd9302b7e42bc58bcc324378b2d06ead5e77b0',
      fsaPrivacyPolicy: 'https://website.fgfdev.com.au/legal-and-compliance/fintech-services-privacy-policy',
      gtmId: 'GTM-KR92XT',
      gtmAuth: '',
      gtmPreview: ''
    }
  },
  test: {
    FGF: {
      apiManagement: 'https://fairgo-test-api-management.azure-api.net',
      ocpApimSubscriptionKey: 'baeec5c32700455a8f58f024b8484a60',
      googleApiKey: GOOGLE_API_KEY,
      authority: "fairgo",
      bankStatementIframeUrl: 'https://bs.fgftest.com.au',
      portalDebug: false,
      authUrl: 'https://auth.fgftest.com.au',
      appUrl: 'https://portal.fgftest.com.au',
      cookieDomain: 'fgftest.com.au',
      authClientId: 'fgf.portal',
      publicWebsite: 'https://website.fgftest.com.au',
      newCustomerForm: 'https://ui.fgftest.com.au',
      contentManagementUrl: 'https://cds.fgftest.com.au',
      contentToken: '8526cc6182773c17f4fec22e48b88fc14adb48e8a7c231e36196900d3ff191412b754a235e478611f9099cefb804db8982d5c01400534171c6172235ef855cd93ef085c0e07cccd12fca1a3114d688cd0d7e0bc59a616e0bb896c491baad311faccaee1ba1fbfbe347046e1e79ef2de8c0b1aff03392c6e0d40a74a184049fae',
      fsaPrivacyPolicy: 'https://website.fgftest.com.au/legal-and-compliance/fintech-services-privacy-policy',
      gtmId: 'GTM-MMDH47J',
      gtmAuth: 'zCAiAcyYf5HNWAmzTvoUqA',
      gtmPreview: 'env-313'
    },
    NIM: {
      apiManagement: 'https://fairgo-test-api-management.azure-api.net',
      ocpApimSubscriptionKey: 'baeec5c32700455a8f58f024b8484a60',
      googleApiKey: GOOGLE_API_KEY,
      authority: "nimble",
      bankStatementIframeUrl: 'https://bs.fgftest.com.au',
      portalDebug: false,
      authUrl: 'https://auth.nimble-test.com.au',
      appUrl: 'https://portal.nimble-test.com.au',
      cookieDomain: 'nimble-test.com.au',
      authClientId: 'fgf.portal',
      publicWebsite: 'https://website.fgftest.com.au',
      newCustomerForm: 'https://ui.nimble-test.com.au',
      contentManagementUrl: 'https://cds.fgftest.com.au',
      contentToken: '8526cc6182773c17f4fec22e48b88fc14adb48e8a7c231e36196900d3ff191412b754a235e478611f9099cefb804db8982d5c01400534171c6172235ef855cd93ef085c0e07cccd12fca1a3114d688cd0d7e0bc59a616e0bb896c491baad311faccaee1ba1fbfbe347046e1e79ef2de8c0b1aff03392c6e0d40a74a184049fae',
      fsaPrivacyPolicy: 'https://website.fgftest.com.au/legal-and-compliance/fintech-services-privacy-policy',
      gtmId: 'GTM-KR92XT',
      gtmAuth: '',
      gtmPreview: ''
    }
  },
  production: {
    FGF: {
      apiManagement: 'https://apim.fairgofinance.com.au',
      ocpApimSubscriptionKey: '01bf156bbed0498aae6c75fcd0f862cf',
      googleApiKey: GOOGLE_API_KEY,
      authority: "fairgo",
      bankStatementIframeUrl: 'https://bs.fairgofinance.com.au',
      portalDebug: false,
      authUrl: 'https://auth.fairgofinance.com.au',
      appUrl: 'https://portal.fairgofinance.com.au',
      cookieDomain: 'fairgofinance.com.au',
      authClientId: 'fgf.portal',
      publicWebsite: 'https://www.fairgofinance.com.au',
      newCustomerForm: 'https://apply.fairgofinance.com.au',
      contentManagementUrl: 'https://cds.fairgofinance.com.au',
      contentToken: 'd2c30e0f3153c199f6343f0f83c873ae537fcc64e339afd78098cd49625d1000c16c616ab0bcf1cf47aae6711c484340ebe759627f5cbf67f8df87de1f8802809e856fabc84609528da872b5f0e296e7840662eab414516f09d121b8565d721d293c2fe71104c96f631dbb472a54281d2c880b796c38bb844649caf784fe31c9',
      fsaPrivacyPolicy: 'https://www.fairgofinance.com.au/legal-and-compliance/fintech-services-privacy-policy',
      gtmId: 'GTM-MMDH47J',
      gtmAuth: 'cR_Lz3XpRd_mt4fXhlKgBw',
      gtmPreview: 'env-1'
    },
    NIM: {
      apiManagement: 'https://apim.fairgofinance.com.au',
      ocpApimSubscriptionKey: '01bf156bbed0498aae6c75fcd0f862cf',
      googleApiKey: GOOGLE_API_KEY,
      authority: "nimble",
      bankStatementIframeUrl: 'https://bs.fairgofinance.com.au',
      portalDebug: false,
      authUrl: 'https://auth.nimble.com.au',
      appUrl: 'https://portal.nimble.com.au',
      cookieDomain: 'nimble.com.au',
      authClientId: 'fgf.portal',
      publicWebsite: 'https://www.fairgofinance.com.au',
      newCustomerForm: 'https://apply.nimble.com.au',
      contentManagementUrl: 'https://cds.fairgofinance.com.au',
      contentToken: 'd2c30e0f3153c199f6343f0f83c873ae537fcc64e339afd78098cd49625d1000c16c616ab0bcf1cf47aae6711c484340ebe759627f5cbf67f8df87de1f8802809e856fabc84609528da872b5f0e296e7840662eab414516f09d121b8565d721d293c2fe71104c96f631dbb472a54281d2c880b796c38bb844649caf784fe31c9',
      fsaPrivacyPolicy: 'https://www.fairgofinance.com.au/legal-and-compliance/fintech-services-privacy-policy',
      gtmId: 'GTM-KR92XT',
      gtmAuth: '',
      gtmPreview: ''
    }
  }
} as const;